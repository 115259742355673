<template>
    <div>
        <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_323_328)">
                <path d="M9.5 6.5H6.5V9.5H5.5V6.5H2.5V5.5H5.5V2.5H6.5V5.5H9.5V6.5Z" fill="white" class="alterColor" />
            </g>
            <defs>
                <clipPath id="clip0_323_328">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
</template>
  
<script>

export default {
    name: "notiSwitch",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor{
fill:$clr-saina-light-dark-main-bg
}

</style>